<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3>
            <span v-if="createType == 'edit'"
              >Edit Lender Account Information
              <v-progress-circular
                indeterminate
                class="ml-3"
                color="primary"
              ></v-progress-circular></span
            ><span v-if="createType == 'add'">Add Lender Account</span>
          </h3>
          <v-card v-if="error" class="danger mb-6">
            <v-card-subtitle class="red--text"
              >There was an error.</v-card-subtitle
            >
            <v-card-text class="red--text">{{ error }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            <span v-if="createType == 'edit'"
              >Edit Lender Account Information</span
            ><span v-if="createType == 'add'">Add Lender Account</span>
          </h3>

          <p class="py-4">
            This is where we are going to add the lender account. Makes it easier for us when creating mortgage authorization documents.
          </p>
          <v-form
            @submit="submitCreateLenderAccount"
            ref="form"
            v-model="formRules.valid"
          >
            <v-text-field
              v-model="lenderAccountData.accountName"
              label="Account Name"
              :rules="formRules.required"
            ></v-text-field>
          
                    <v-select
              :items="accountTypeOptions"
              v-model="lenderAccountData.accountType"
              label="Account Type"
              :rules="formRules.required"
            ></v-select>
            <v-text-field
              v-model="lenderAccountData.accountNumber"
              label="Account Number"
            ></v-text-field>
            <v-text-field
              v-model="lenderAccountData.accountBalance"
              label="Account Balance"
              type="number"
            ></v-text-field>

            <v-row>
              <v-col cols="12">
                <v-card v-if="submitError" class="danger mb-6">
                  <v-card-subtitle class="red--text"
                    >There was an error.</v-card-subtitle
                  >
                  <v-card-text class="red--text">{{ submitError }}</v-card-text>
                </v-card>
                <v-btn
                  v-if="createType == 'add'"
                  :disabled="formLoading"
                  @click="submitCreateLenderAccount"
                  >Create Account
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  ></v-progress-circular
                ></v-btn>
                <v-btn
                  v-if="createType == 'edit'"
                  :disabled="formLoading"
                  @click="submitUpdateLenderAccount"
                  >Update Account
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  ></v-progress-circular
                ></v-btn>
                <v-btn
                  dark
                  class="ml-4"
                  @click="
                    $router.push({
                      path: `/dashboard/lender/read/${lenderAccountData.lenderId}`,
                    })
                  "
                  >Cancel</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "LenderAccountAdd",
  props: ["lenderAccountId"],
  data() {
    return {
      createType: "",
      formLoading: false,
      componentLoading: true,
      error: "",
      submitError: "",
      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
      },
      lenderAccountData: {
        lenderId: "",
        accountName: "",
        accountType: "",
        accountNumber: "",
        accountBalance: "",
      },
      accountTypeOptions: [
        { value: "rrsp", text: "RRSP" },
        { value: "tfsa", text: "TFSA" },
        { value: "cash", text: "Cash" },
        { value: "business", text: "Business" }
      ],
    };
  },
  methods: {
    submitCreateLenderAccount() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .post("/lenderAccount", this.lenderAccountData)
          .then((res) => {
            this.formLoading = false;
            console.log(res.data)
            this.$router.push(
              `/dashboard/lenderAccount/read/${res.data.insertedLenderAccountId}`
            );
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },
    submitUpdateLenderAccount() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .patch(
            `/lenderAccount/${this.lenderAccountData.lenderId}`,
            this.lenderAccountData
          )
          .then((res) => {
            this.formLoading = false;

            this.$router.push(
              `/dashboard/lender/read/${this.lenderAccountData.lenderId}`
            );
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },
  },
  mounted() {
    if (this.$route.name == "lenderAccountAdd") {
      this.createType = "add";
      this.lenderAccountData.lenderId = parseInt(this.$route.query.lenderId);
      this.componentLoading = false;
    } else {
      this.createType = "edit";
      API()
        .get(`/lenderAccount/read/${this.lenderAccountId}`)
        .then((res) => {
          this.lenderAccountData = res.data.recordset[0];
          this.componentLoading = false;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.dispatch("Auth/silentLogin");
            }
          }
          console.log(e);
        });
    }
  },
};
</script>

<style>
</style>